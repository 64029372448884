import React, { useState } from 'react'
import { AppBar, Grid, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system'
import { ReactSVG } from 'react-svg';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import useMedia from '../../hooks/useMedia';
import useConfig from '../../hooks/useConfig';
import { TextWithRedLine } from '../CommonComponents';

const AppBarStyled = styled(AppBar)({
    background: 'transparent',
    boxShadow: 'none',
    padding: '0 15rem',
    position: 'static',
    height: '180px',

    '@media (max-width: 1440px)': {
        padding: '0 5rem',
    },

    '@media (max-width: 1200px)': {
        position: 'fixed',
        backgroundColor: '#171717',
        padding: '0 2rem',
        height: '90px',
    }
})

const LinkStyled = styled(TextWithRedLine)({
    position: 'relative',
    fontSize: '1.75rem',
    fontWeight: '900',

    '@media (max-width: 1600px)': {
        fontSize: '1.2rem',
    },
    
    '::after': {
        transition: 'left 0.3s',
        width: '30px',
        height: '5px',
    },

    ':hover': {
        '::after': {
            left: 'calc(100% - 30px)'
        }
    },

    ':active': {
        '::after': {
            transition: '0.15s',
            left: '0',
            width: '100%'
        }
    }
})

const Logo = styled(ReactSVG)({
    svg: {
        height: 'auto',
        width: '175px',
        cursor: 'pointer',
        transition: 'transform 0.25s, box-shadow 0.25s',
        ':hover': {
            transform: 'scale(1.1)',
        }
    },

    '@media (max-width: 1200px)': {
        svg: {
            height: 'auto',
            width: '75px',
        }
    }
})

const MenuStyled = styled(Menu)({
    'ul': {
        backgroundColor: '#272727',
        color: 'whitesmoke',
    }
})

const DesktopView = ({ config }) => {
    return (
        <AppBarStyled>
            <Grid container spacing={0} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={2} sx={{ textAlign: 'center'}}>
                    <a href={config.links.discord}>
                        <LinkStyled variant='span' disableRipple>
                            BUY SERVER
                        </LinkStyled>
                    </a>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'center'}}>
                    <Link to='/servers' onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <LinkStyled variant='span' disableRipple>
                            OUR SERVERS
                        </LinkStyled>
                    </Link>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'center' }}>
                    <Link to='/about' onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <Logo src={process.env.PUBLIC_URL + '/assets/svg/alphu.xyz.svg' } />
                    </Link>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'center' }}>
                    <a href={`mailto:${config.mail}`}>
                        <LinkStyled variant='span' disableRipple>
                            CONTACT US
                        </LinkStyled>
                    </a>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'center'}}>
                    <Link to='/' onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <LinkStyled variant='span' disableRipple>
                            LOGIN
                        </LinkStyled>
                    </Link>
                </Grid>
            </Grid>
        </AppBarStyled>
    )
}

const MobileView = ({ config }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    return (
        <AppBarStyled>
            <Grid container spacing={0} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
                <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Link to='/about' onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <Logo src={process.env.PUBLIC_URL + '/assets/svg/alphu.xyz.svg' } />
                    </Link>
                    <Typography sx={{ fontSize: '2.25rem', fontWeight: '800', margin: '0 0.5rem', '@media (max-width: 480px)': { display: 'none' } }}>
                        ALPHU HOSTING
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'center'}}>
                    <IconButton sx={{ color: 'whitesmoke', transform: 'scale(1.75)', float: 'right' }} onClick={handleClick}>
                        { !open ? <MenuIcon color='whitesmoke' /> : <MenuOpenIcon color='whitesmoke' /> }
                    </IconButton>
                    <MenuStyled anchorEl={anchorEl} open={open} onClose={handleClose}>
                        <MenuItem onClick={handleClose}>
                            <a href={config.links.discord} style={{ color: 'whitesmoke' }}>
                                BUY SERVER
                            </a>
                        </MenuItem>
                        <Link to='/servers' onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <MenuItem onClick={handleClose}>
                                OUR SERVERS
                            </MenuItem>
                        </Link>
                        <MenuItem onClick={handleClose}>
                            <a href={`mailto:${config.mail}`} style={{ color: 'whitesmoke' }}>
                                CONTACT US
                            </a>
                        </MenuItem>
                        <Link to='/' onClick={window.scrollTo({ top: 0, behavior: 'smooth' })}>
                            <MenuItem onClick={handleClose}>
                                LOGIN
                            </MenuItem>
                        </Link>
                    </MenuStyled>
                </Grid>
            </Grid>
        </AppBarStyled>
    )
}

const Navbar = () => {
    const { matches } = useMedia()
    const { config } = useConfig()
    return !matches ? <DesktopView config={config} /> : <MobileView config={config} />
}

export default Navbar