import { useContext, useEffect, useState, createContext } from "react"

export const ConfigContext = createContext()

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const fetchConfig = async () => {
        try {
            setIsLoading(true)
            const response = await fetch('https://alphu.xyz/api/config.json')
            const data = await response.json()
            setConfig(data)
        } catch (error) {
            console.error('Error fetching configuration:', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchConfig()
    }, [])

    return (
        <ConfigContext.Provider value={{ config, isLoading }}>
            {children}
        </ConfigContext.Provider>
    )
}

export default function useConfig() {
    return useContext(ConfigContext)
}
